<template>
  <v-app>
    <v-card>
      <v-data-table
        v-model="selected"
        :headers="selectedHeaders"
        :items="itemsData"
        :items-per-page="itemPerPage"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        class="elevation-1 uncierre-table"
        mobile-breakpoint="0"
        :show-select="showSelect"
        :item-class="tdClass"
        multi-sort
        hide-default-footer
        v-sortable-table="{ onEnd: sortTheHeadersAndUpdateTheKey }"
        :key="anIncreasingNumber"
      >
        <template
          v-for="(key, index) in headers.map(({ value }) => value)"
          v-slot:[`header.${key}`]="{ header }"
        >
          {{ header.text }}
          <v-menu
            offset-y
            :close-on-content-click="false"
            v-if="header.filter"
            :key="index"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon
                  x-small
                  :color="header.filterValue.type ? 'primary' : ''"
                >
                  fa-filter
                </v-icon>
              </v-btn>
            </template>
            <div style="background-color: white; width: 280px">
              <v-select
                :items="getFilterOptions(header.filterType)"
                item-text="name"
                item-value="value"
                v-model="header.filterValue.type"
                v-on:change="changeFilter(header)"
                class="pa-4"
                label="Filtro"
                :autofocus="true"
                dense
              ></v-select>
              <v-btn
                @click="clearFilter(header)"
                small
                text
                color="primary"
                class="ml-2 mb-2"
                >Borrar</v-btn
              >
            </div>
          </v-menu>
        </template>

        <template v-slot:[`body.prepend`]>
          <tr>
            <td></td>
            <td
              v-for="(header, index) in headers.filter(
                (header) => header.hide !== true
              )"
              v-bind:key="index"
              class="filter-text"
            >
              <v-text-field
                v-model="header.filterValue.value"
                @input="changeFilter(header)"
                type="text"
                v-if="
                  header.filter &&
                  header.filterType != 'Estado' &&
                  header.filterType != 'Date' &&
                  header.filterType != 'Estado Compossed'
                "
              ></v-text-field>
              <v-select
                :items="header.filterStatus"
                item-text="name"
                item-value="value"
                v-model="header.filterValue.value"
                v-on:change="changeFilter(header)"
                class="pa-4"
                label="Estados"
                v-if="
                  header.filter &&
                  (header.filterType === 'Estado' ||
                    header.filterType === 'Estado Compossed')
                "
                dense
              ></v-select>
              <v-menu
                v-model="header.openCalendar"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                v-if="header.filter && header.filterType === 'Date'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="header.filterValue.value"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="header.filterValue.value"
                  :range="header.filterValue.type === '_between'"
                  @input="changeFilter(header)"
                ></v-date-picker>
              </v-menu>
            </td>
          </tr>
        </template>

        <template
          v-for="(key, index) in headers.map(({ value }) => value)"
          v-slot:[`item.${key}`]="{ item }"
        >
          <td :class="key.toString().toLowerCase()">
            <div v-if="!headers[index].cellRenderer" :key="index">
              {{ item[key] }}
            </div>

            <div v-if="headers[index].cellRenderer === 'flat'" :key="index">
              <div :class="item[key].toString().toLowerCase()">
                {{ headers[index].cellRendererParams(item).value }}
              </div>
            </div>

            <div v-if="headers[index].cellRenderer === 'composed'" :key="index">
              {{ headers[index].cellRendererParams(item) }}
            </div>
            <div v-if="headers[index].cellRenderer === 'chip'" :key="index">
              <v-chip
                dark
                :color="headers[index].cellRendererParams(item).color"
              >
                {{ headers[index].cellRendererParams(item).value }}
              </v-chip>
            </div>
            <div v-if="headers[index].cellRenderer === 'actions'" :key="index">
              <vs-row vs-type="flex" vs-justify="flex-end">
                <vs-col
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="center"
                  vs-w="12"
                >
                  <vs-button
                    v-for="action in headers[index].cellRendererParams(item)"
                    :color="action.color"
                    @click="action.event(item)"
                    :class="action.class || 'mr-2'"
                    type="border"
                    icon-pack="feather"
                    :icon="action.icon"
                    :title="action.title"
                    v-bind:key="action.index"
                    :disabled="action.disabled"
                  >
                    {{ action.label ? action.label : "" }}
                  </vs-button>
                </vs-col>
              </vs-row>
            </div>
          </td>
        </template>

        <template v-slot:top="{ pagination, options, updateOptions }">
          <vs-row>
            <vs-col class="sm:w-full lg:w-1/2 p-1">
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                label="Columnas"
                multiple
                outlined
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 5">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 5" class="grey--text caption"
                    >(+{{ selectedHeaders.length - 5 }} mas)</span
                  >
                </template>
              </v-select>
            </vs-col>
            <vs-col class="sm:w-full lg:w-1/2 p-1">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="Filas por pagina"
              />
            </vs-col>
          </vs-row>
        </template>
        <template v-slot:[`body.append`]>
          <tr>
            <td class="font-weight-bold">Totales</td>
            <td class="font-weight-bold" v-for="(header, index) in headers">
              <span v-if="header.summaryType === 'sum'" class="text-xs">
                {{ totalize(header.value) }}
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import Sortable from "sortablejs";
import dayjs from "../helpers/days";
import currencies from "../helpers/currencies";

function watchClass(targetNode, classToWatch) {
  let lastClassState = targetNode.classList.contains(classToWatch);
  const observer = new MutationObserver((mutationsList) => {
    for (let i = 0; i < mutationsList.length; i++) {
      const mutation = mutationsList[i];
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "class"
      ) {
        const currentClassState =
          mutation.target.classList.contains(classToWatch);
        if (lastClassState !== currentClassState) {
          lastClassState = currentClassState;
          if (!currentClassState) {
            mutation.target.classList.add("sortHandle");
          }
        }
      }
    }
  });
  observer.observe(targetNode, { attributes: true });
}

export default {
  name: "DataTable",
  data() {
    return {
      anIncreasingNumber: 0,
      options: {},
      selected: [],
      selectedHeaders: [],
      filters: {},
      filterStatus: "",
      timeOutId: null,
      filtering: "",
      totalColumns: {},
    };
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    itemsData: {
      type: Array,
      required: true,
    },
    itemPerPage: {
      default: 10,
      type: Number,
    },
    totalItems: {
      require: true,
      type: Number,
    },
    showSelect: {
      default: true,
      type: Boolean,
    },
    itemsSelected: {
      type: Array,
    },
    load: {
      type: Boolean,
      required: true,
    },
    sortBy: {
      type: String,
    },
    sortAsc: {
      type: Boolean,
    },
  },
  watch: {
    options: {
      handler() {
        this.serverSideMethod();
      },
      deep: true,
    },
    selected(seleccion) {
      this.$emit("changeSelect", seleccion);
    },
    selectedHeaders(newSelectedHeaders) {
      if (newSelectedHeaders.length) {
        this.headers.filter((s) => newSelectedHeaders.includes(s));
      } else {
        newSelectedHeaders = this.headers;
        this.headers.filter((s) => newSelectedHeaders.includes(s));
      }
    },
  },
  async mounted() {
    this.selected = this.itemsSelected;
    this.headers.forEach((header) => {
      if (header.filter) {
        if (header.filterType === "Number") header.filterValue.type = "_eq";
        else if (
          header.filterType === "Estado" ||
          header.filterType === "Estado Compossed"
        )
          header.filterValue.type = "_eq";
        else if (header.filterType === "Date")
          header.filterValue.type = "_between";
        else header.filterValue.type = "_ilike";
      }
    });
    this.selectedHeaders = this.headers;
    this.selectedHeaders = this.selectedHeaders.filter((s) => !s.hide);
  },
  methods: {
    tdClass(item) {
      return item.status ? item.status.toLowerCase() : "";
    },
    createFilter(item) {
      const filters = this.filters;
      let filterStatus = this.filterStatus;

      if (item.filterType === "Date") item.openCalendar = false;

      let value = item.filterValue.value;

      if (item.filterType === "Estado") {
        filterStatus = item.filterQuotes
          ? `, status:{_eq:"${value}"}`
          : `, status:{_eq:${value}}`;
        return (
          JSON.stringify(filters)
            .replace(/"([^"]+)":/g, "$1:")
            .slice(1, -1) + filterStatus
        );
      }

      const scoped = this;
      if (!item.filterValue.value) {
        if (item.filterType === "Attribute") {
          delete filters["property_attribute_values"];
        } else {
          delete filters[item.value.split(".")[0]];
        }
      } else {
        if (item.value.indexOf(".") > 0) {
          const obj = {};
          const filter = item.value.split(".");
          filter.reduce(function (r, a, i) {
            r[a] = r[a] || {};
            if (i === filter.length - 1)
              r[a] = scoped.createOperator(item.filterValue.type, value);
            return r[a];
          }, obj);
          filters[filter[0]] = obj[filter[0]];
        } else {
          if (item.filterType === "Attribute") {
            if (value.toUpperCase() === "NO") value = false;
            else if (value.toUpperCase() === "SI") value = true;

            filters["property_attribute_values"] = {
              property_attribute: { _eq: item.value },
              value: this.createOperator(item.filterValue.type, value),
            };
          } else {
            filters[item.value] = this.createOperator(
              item.filterValue.type,
              value
            );
          }
        }
      }

      return (
        JSON.stringify(filters)
          .replace(/"([^"]+)":/g, "$1:")
          .slice(1, -1) + filterStatus
      );
    },
    createOperator(operator, value) {
      if (operator === "_ilike") return { [operator]: `%${value}%` };
      else if (operator === "_between") {
        const date2 =
          value.length <= 1
            ? dayjs(value[0]).add(1, "day")
            : dayjs(value[1]).add(1, "day");
        return {
          ["_gte"]: `${value[0]}`,
          ["_lte"]: `${date2.format("YYYY-MM-DD")}`,
        };
      } else return { [operator]: `${value}` };
    },
    createOrder(sortBy, sortDesc) {
      const orders = {};
      sortBy.forEach((order, index) => {
        if (order.indexOf(".") > 0) {
          const obj = {};
          const filter = order.split(".");
          filter.reduce(function (r, a, i) {
            r[a] = r[a] || {};
            if (i === filter.length - 1)
              r[a] = sortDesc[index] ? "desc" : "asc";
            return r[a];
          }, obj);
          orders[filter[0]] = obj[filter[0]];
        } else {
          orders[order] = sortDesc[index] ? "desc" : "asc";
        }
      });

      if (!orders.length && this.sortBy) {
        orders[this.sortBy] = this.sortAsc ? "asc" : "desc";
      }
      let orderBy = JSON.stringify(orders).replace(/"/g, "");
      return orderBy ? `order_by: ${orderBy}` : "";
    },
    serverSideMethod(isFilter = false, item) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      if (isFilter) this.filtering = this.createFilter(item);
      const ordering = this.createOrder(sortBy, sortDesc);
      const pagination =
        itemsPerPage != -1
          ? `, limit:${itemsPerPage}, offset: ${(page - 1) * itemsPerPage}`
          : "";

      const options = { ordering, filtering: this.filtering, pagination };

      this.$emit("serverSideMethod", options);
    },
    getFilterOptions(type) {
      if (type === "Number") {
        return [
          { name: "Igual que", value: "_eq" },
          { name: "Mayor que", value: "_gt" },
          { name: "Menor que", value: "_lt" },
          { name: "Mayor o igual que", value: "_gte" },
          { name: "Menor o igual que", value: "_lte" },
        ];
      } else if (type === "Estado") {
        return [{ name: "Igual que", value: "_eq" }];
      } else if (type === "Date") {
        return [
          { name: "Rango", value: "_between" },
          { name: "Mayor que", value: "_gt" },
          { name: "Menor que", value: "_lt" },
          { name: "Mayor o igual que", value: "_gte" },
          { name: "Menor o igual que", value: "_lte" },
        ];
      } else {
        return [{ name: "Contiene", value: "_ilike" }];
      }
    },
    changeFilter(header) {
      if (this.timeOutId) {
        clearTimeout(this.timeOutId);
      }
      this.timeOutId = setTimeout(() => {
        this.serverSideMethod(true, header);
      }, 800);
    },
    clearFilter(header) {
      header.filterValue.value = "";
      this.serverSideMethod(true, header);
    },
    sortTheHeadersAndUpdateTheKey(evt) {
      const headersTmp = this.headers;
      const oldIndex = evt.oldIndex - 1;
      const newIndex = evt.newIndex - 1;
      headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
      this.anIncreasingNumber += 1;
    },
    getFormatCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];
      return currencies.currencyFormatter({ value, currency: currencySymbol });
    },
    totalize(column) {
      const item = this.itemsData.map((item) => item[column]);
      return this.getFormatCurrency(
        item.reduce((total, value) => total + value, 0),
        0
      );
    },
  },
  computed: {
    loading: {
      get() {
        return this.load;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  directives: {
    "sortable-table": {
      update: (el, binding) => {
        el.querySelectorAll("th").forEach((draggableEl) => {
          watchClass(draggableEl, "sortHandle");
          draggableEl.classList.add("sortHandle");
        });
        Sortable.create(
          el.querySelector("tr"),
          binding.value ? { ...binding.value, handle: ".sortHandle" } : {}
        );
      },
    },
  },
};
</script>
<style lang="scss">
.v-application {
  font-family: "Poppins", sans-serif !important;
}

th.text-start.sortable {
  padding: 0px !important;
  margin: 0px I !important;
}
td.filter-text {
  padding-left: 0px !important;
  margin: 0px I !important;
}

td.filter-text div {
  padding: 0px;
}

td.filter-text input {
  background-color: transparent;
}

td.filter-text .v-text-field__details {
  display: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 1rem !important;
  font-family: "Poppins", sans-serif !important;
}

.vs-button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
  padding: 10px 7px 10px 10px !important;
}

.uncierre-table tr td.status {
  font-weight: bold;
  text-align: center !important;
}

.uncierre-table tr.available td.status,
.uncierre-table tr.open td.status {
  background: #f6f7f8;
}

.uncierre-table tr.reserved td {
  color: #29c971;
}
.uncierre-table tr.reserved td.status {
  background: #d3fceb;
}

.uncierre-table tr.optioned td {
  color: #1973e8;
}
.uncierre-table tr.optioned td.status {
  background: #cedff8;
}

.uncierre-table tr.sold td {
  color: #00a53d;
}
.uncierre-table tr.sold td.status {
  background: #29c971;
  color: #fff !important;
}

.uncierre-table tr.blocked td {
  color: #f24646;
}
.uncierre-table tr.blocked td.status {
  background: #f24646;
  color: #fff !important;
}

.uncierre-table tr.expired td,
.uncierre-table tr.assigned td {
  color: #ebb41b;
}
.uncierre-table tr.expired td.status,
.uncierre-table tr.assigned td.status {
  background: #efeabd;
}
</style>
